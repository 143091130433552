import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const authAtom = atom({
  key: 'auth',
  default: {
    empNo: null,
    id: null,
    name: null,
    mobilePhoneNumber: null,
    token: null,
    lastLoginAt: null,
    regDate: null,
  },
  effects_UNSTABLE: [persistAtom],
})

export const ownerAtom = atom({
  key: 'owner',
  default: {
    freeTrialEndDate: '',
    shopNo: '',
    token: '',
  },
  effects_UNSTABLE: [persistAtom],
})
