import { StyledInput } from './Input.styles'
import { InputProps } from './Input.types'

const Input = ({ isRequired, ...props }: InputProps) => {
  return (
    <StyledInput
      required={isRequired}
      disabled={props.status === 'DISABLED' || props.disabled}
      {...props}
    />
  )
}

export default Input
