import * as React from 'react'
import { PathRouteProps } from 'react-router-dom'
import qs from 'qs'
import { AxiosError } from 'axios'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'

import { EyeIcon, EyeOffIcon } from 'components/Icons/svg'

import { getAuthPhoneNumberCode, sendAuthPhoneNumber, registerUser } from 'services/auth.service'

import Timer from 'components/Timer'
import { authAtom } from 'stores/atoms'
import { queryPramsAtom } from 'stores/atoms/users'
import { RegisterTemplate } from 'template/RegisterTemplate'
import { TextField } from 'components/common'
import { useHandlePassword } from 'hooks/useHandlePassword'
import { useHandleUserId } from 'hooks/useHandleUserId'
import { useHandleMobile } from 'hooks/useHandleMobile'

interface RegisterPageProps extends PathRouteProps {}

export const RegisterPage: React.FC<RegisterPageProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isSmallerThanTablet] = useMediaQuery('(max-width: 768px)')

  const setAuthState = useSetRecoilState(authAtom)
  const setQueryParams = useSetRecoilState(queryPramsAtom)

  const [isFinishTime, setisFinishTime] = React.useState(true)
  const [isResetTime, setIsResetTime] = React.useState(false)

  const [isAuthenticating, setIsAuthenticating] = React.useState(false)
  const [showResendButton, setShowResendButton] = React.useState(false)
  const [showCodeInput, setShowCodeInput] = React.useState(false)
  const [isCodeValid, setIsCodeValid] = React.useState<boolean | null>(null)

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
  })

  const { userId, onChangeUserId } = useHandleUserId()
  const {
    password,
    passwordConfirm,
    getPasswordConfirmStatus,
    getPasswordStatus,
    getHelptext,
    onChangePassword,
    onChangePasswordConfirm,
    isShowErrorText,
    showPassword,
    showPasswordConfirm,
    onChangePasswordConfirmType,
    onChangePasswordType,
  } = useHandlePassword()
  const { userName, validateUserName, mobileNumber, onChangeMobileNumber } = useHandleMobile()

  const isValidAll =
    isCodeValid &&
    [userId.status, getPasswordStatus(), getPasswordConfirmStatus(), userName.status].every(
      (status) => status === 'SUCCESS01',
    )

  const stateOfButtonDisable = mobileNumber.status === 'DEFAULT' || (showCodeInput && !isFinishTime)

  const qsData = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  React.useEffect(() => {
    const { returnUrl } = qsData
    if (Object.keys(qsData).length) {
      setQueryParams({
        returnUrl,
      })
    }
  }, [qsData, setQueryParams])

  React.useEffect(() => {
    setisFinishTime(true)
    setIsResetTime(false)
    setShowResendButton(false)
  }, [mobileNumber])

  const onAuthPhoneCode = async () => {
    try {
      setIsAuthenticating(true)
      const { status } = await sendAuthPhoneNumber({
        certificationText: getValues('certificationText'),
        certificationType: 'SMS_CERT',
        mobilePhoneNumber: mobileNumber.value,
      })

      if (status === 204) {
        setIsCodeValid(true)
      }
    } catch (error) {
      setIsCodeValid(false)
    } finally {
      setIsAuthenticating(false)
    }
  }

  const onClickRequestPhoneCode = React.useCallback(async () => {
    try {
      await getAuthPhoneNumberCode({
        certificationType: 'SMS_CERT',
        mobilePhoneNumber: mobileNumber.value,
      })
      setShowResendButton(false)
      setShowCodeInput(true)
      setisFinishTime(false)
    } catch (error) {
      console.error(error)
    }
  }, [mobileNumber])

  const onClickResendPhoneCode = React.useCallback(async () => {
    try {
      await getAuthPhoneNumberCode({
        certificationType: 'SMS_CERT',
        mobilePhoneNumber: mobileNumber.value,
      })
      setIsResetTime(true)
      setShowResendButton(false)
    } catch (error) {
      console.error(error)
    }
  }, [mobileNumber])

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!isValidAll) {
      return
    }

    const payload = {
      id: userId.value,
      mobilePhoneNumber: mobileNumber.value,
      name: userName.value,
      password,
    }
    try {
      const {
        data: { data, errorResponse },
      } = await registerUser(payload)

      if (errorResponse.status !== 200) {
        alert(errorResponse.message)
        return
      }

      setAuthState(data)
      return navigate('/register/roles')
    } catch (err: unknown) {
      setIsCodeValid(false)
      const error = err as AxiosError
      alert(error.response?.data.message)
    }
  }

  return (
    <RegisterTemplate>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <RegisterFormHeading
            fontSize={{
              base: '16px',
              md: '24px',
            }}
          >
            기본 정보 입력
          </RegisterFormHeading>
          <FormControl id="id" mb={30}>
            <FormLabel>회원정보</FormLabel>
            <TextField
              placeholder="아이디를 입력해주세요."
              status={userId.status}
              helpText={userId.helpText}
              value={userId.value}
              onChange={onChangeUserId}
            />
          </FormControl>
          <FormControl id="password">
            <InputGroup size="md">
              <TextField
                type={showPassword ? 'text' : 'password'}
                placeholder="비밀번호"
                status={getPasswordStatus()}
                value={password}
                onChange={onChangePassword}
              />
              <InputRightElement width="2.75rem" onClick={onChangePasswordType} cursor="pointer">
                {showPassword ? <EyeIcon /> : <EyeOffIcon />}
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id="passwordConfirm" mt={2.5} mb={42}>
            <InputGroup size="md">
              <TextFieldPasswordConfirm
                type={showPasswordConfirm ? 'text' : 'password'}
                placeholder="비밀번호 확인"
                status={getPasswordConfirmStatus()}
                value={passwordConfirm}
                helpText={getHelptext()}
                onChange={onChangePasswordConfirm}
                isShowErrorText={isShowErrorText}
              />
              <InputRightElement
                width="2.75rem"
                onClick={onChangePasswordConfirmType}
                cursor="pointer"
              >
                {showPasswordConfirm ? <EyeIcon /> : <EyeOffIcon />}
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id="name">
            <FormLabel>휴대폰 인증</FormLabel>
            <TextField
              placeholder="이름을 입력해주세요."
              status={userName.status}
              value={userName.value}
              helpText={userName.helpText}
              onChange={validateUserName}
            />
          </FormControl>
          <FormControl id="mobilePhoneNumber" mt={2.5}>
            <Flex alignItems="flex-start">
              <TextField
                placeholder="휴대폰 번호를 입력해주세요."
                value={mobileNumber.value}
                onChange={onChangeMobileNumber}
                helpText={mobileNumber.helpText}
                status={mobileNumber.status}
              />
              <Button
                ml={4}
                borderRadius={100}
                minW={75}
                variant="outline"
                disabled={stateOfButtonDisable as boolean}
                onClick={onClickRequestPhoneCode}
              >
                {showCodeInput ? (
                  isFinishTime ? (
                    '전송'
                  ) : (
                    <Timer
                      mm={5}
                      ss={0}
                      isResetTime={isResetTime}
                      setIsResetTime={setIsResetTime}
                      setisFinishTime={setisFinishTime}
                      setShowResendButton={setShowResendButton}
                    />
                  )
                ) : (
                  '전송'
                )}
              </Button>
            </Flex>
          </FormControl>

          {showCodeInput && mobileNumber.status === 'SUCCESS02' && (
            <FormControl id="certificationText" mt={5}>
              <Flex alignItems="flex-end">
                <InputGroup mr={4}>
                  <Input
                    type="number"
                    autoComplete="off"
                    fontSize="sm"
                    style={{ boxShadow: 'none' }}
                    variant="flushed"
                    borderColor={isCodeValid === false ? 'error_color' : 'line_gray'}
                    focusBorderColor={isCodeValid === false ? 'error_color' : 'main_blue'}
                    placeholder="인증번호를 입력해주세요"
                    {...register('certificationText', { required: true })}
                  />
                  {showResendButton && !isFinishTime && (
                    <InputRightElement cursor="pointer">
                      <Text fontSize="sm" color="main_blue" onClick={onClickResendPhoneCode}>
                        재전송
                      </Text>
                    </InputRightElement>
                  )}
                </InputGroup>
                <Button
                  borderRadius={100}
                  minW={75}
                  variant="outline"
                  onClick={onAuthPhoneCode}
                  isLoading={isAuthenticating}
                  disabled={isCodeValid === true}
                >
                  인증
                </Button>
              </Flex>
              {isCodeValid === false && (
                <FormHelperText fontSize="xs" mt={2.5} color="error_color">
                  인증번호를 확인해주세요
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Box>
        <Box>
          {isSmallerThanTablet ? (
            <Box mb={4}>
              <Text color="default_text_gray" fontSize={12} textAlign="center">
                공비서 회원가입을 완료하면 공비서, 공비서 스토어의
              </Text>

              <Text color="default_text_gray" fontSize={12} textAlign="center">
                <Link
                  _active={{ boxShadow: 'none' }}
                  href="https://www.gongbiz.kr/crm/terms.html"
                  isExternal
                  textDecoration="underline"
                >
                  통합 서비스 이용약관
                </Link>{' '}
                및
                <Link
                  _active={{ boxShadow: 'none' }}
                  href="https://www.gongbiz.kr/crm/privacy.html"
                  isExternal
                  textDecoration="underline"
                >
                  개인정보 처리방침
                </Link>
                에
              </Text>
              <Text color="default_text_gray" fontSize={12} textAlign="center">
                동의하시면 완료하기를 눌러주세요.
              </Text>
            </Box>
          ) : (
            <Box mb={4}>
              <Text color="default_text_gray" fontSize={12} textAlign="center">
                공비서 회원가입을 완료하면 공비서, 공비서스토어의{' '}
                <Link
                  _active={{ boxShadow: 'none' }}
                  href="https://www.gongbiz.kr/crm/terms.html"
                  isExternal
                  textDecoration="underline"
                >
                  통합 서비스 이용약관
                </Link>{' '}
                및
              </Text>
              <Text color="default_text_gray" fontSize={12} textAlign="center">
                <Link
                  _active={{ boxShadow: 'none' }}
                  href="https://www.gongbiz.kr/crm/privacy.html"
                  isExternal
                  textDecoration="underline"
                >
                  개인정보 처리방침
                </Link>
                에 동의하신것으로 간주합니다.
              </Text>
            </Box>
          )}

          <Button
            isLoading={isSubmitting}
            isFullWidth
            size="lg"
            type="submit"
            disabled={!isValidAll}
          >
            완료
          </Button>
        </Box>
      </StyledForm>
    </RegisterTemplate>
  )
}

const StyledForm = styled.form`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input::placeholder {
    color: ${({ theme }) => theme.color.gray[300]};
  }
`

const RegisterFormHeading = styled(Heading)`
  margin-bottom: 42px;
  text-align: center;
`

const TextFieldPasswordConfirm = styled(TextField)<{ isShowErrorText: boolean }>`
  ${({ theme, isShowErrorText }) => {
    if (isShowErrorText) {
      return css`
        & + p {
          color: ${theme.color.red[300]};
        }
      `
    }
  }}
`
