import media from 'lib/styles/media'
import palette from 'lib/styles/palette'
import styled, { css } from 'styled-components'

const FooterBlock = styled.footer`
  border-top: 1px solid ${palette.line_gray};
  margin-bottom: 0;
  height: 200px;
  width: 100%;
  ${media.mobile} {
    background: #fff;
    max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  margin: auto;
  width: 1140px;

  ${media.mobile} {
    width: 100%;
    flex-direction: column;
    padding-top: 6px;
  }
`

const FooterColumn = styled.div<{ isReverse?: boolean }>`
  flex: 1;
  justify-content: space-between;
  ${(props) =>
    props.isReverse &&
    css`
      ${media.mobile} {
        display: flex;
        flex-direction: column-reverse;
      }
    `}
`

const FooterTitle = styled.h1`
  font-size: 18px;
  font-weight: 700;
  color: #676a6f;
  margin-bottom: 1rem;
`

const FooterContent = styled.span`
  color: #707a82;
  font-size: 12px;
  line-height: 2;
  letter-spacing: -0.5px;
`

const FooterIconWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 10px;
`

const FooterContentWrapper = styled.div`
  display: flex;
  height: 22px;

  @media screen and (min-width: 769px) {
    & + & {
      margin-top: 1.25rem;
    }
  }

  ${media.mobile} {
    margin-top: 0.75rem;
  }
`

const Divider = styled.span`
  margin: 0 10px;
  position: relative;
  &::before {
    content: '';
    width: 0.5px;
    height: 12px;
    background: #d4d9d9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export {
  FooterBlock,
  FooterWrapper,
  FooterColumn,
  FooterTitle,
  FooterContent,
  FooterContentWrapper,
  FooterIconWrapper,
  Divider,
}
