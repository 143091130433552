import React from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import MainTemplate from 'template/MainTemplate'

import { RegisterPage } from 'pages/auth/Register'
import { RegisterOwnerPage } from 'pages/auth/Register/Owner'
import { RegisterRolesPage } from 'pages/auth/Register/Roles'
import { RegisterEmployeePage } from 'pages/auth/Register/Employee'
import { RegisterSuccessPage } from 'pages/auth/Register/Success'

import { Header } from 'layouts/Header'
import { Footer } from 'layouts/Footer'

import ErrorBoundary from 'components/error/ErrorBoundary'

const App: React.FC<{}> = () => {
  const location = useLocation()

  React.useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [location])

  return (
    <MainTemplate>
      <Helmet>
        <title>공비서 CRM</title>
        <meta name="description" content="공비서 CRM" />
        <link
          rel="preload"
          href="/ssr/static/fonts/Pretendard-Regular.subset.woff2"
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/ssr/static/fonts/Pretendard-Medium.subset.woff2"
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/ssr/static/fonts/Pretendard-Bold.subset.woff2"
          as="font"
          type="font/woff2"
        />
        <link rel="stylesheet" href="/ssr/static/styles/font.css" />
      </Helmet>
      <Header />
      <ErrorBoundary>
        <Routes>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/register/roles" element={<RegisterRolesPage />} />
          <Route path="/register/owner" element={<RegisterOwnerPage />} />
          <Route path="/register/employee" element={<RegisterEmployeePage />} />
          <Route path="/register/employee/success" element={<RegisterSuccessPage />} />
          <Route path="*" element={<Navigate to={'/register'} />} />
        </Routes>
      </ErrorBoundary>
      <Footer />
    </MainTemplate>
  )
}

export default App
