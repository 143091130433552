import React from 'react'
import ReactDOM from 'react-dom'
import {
  ModalWrapper,
  Backdrop,
  ModalBlock,
  ModalHeader,
  CloseButton,
  ModalContent,
} from './Modal.styles'
import { ModalProps } from './Modal.types'

import { CloseIcon } from '../Icons/svg'
import { Heading } from '@chakra-ui/react'

export const Modal: React.FC<ModalProps> = ({ isShown, hide, modalContent, headerText }) => {
  isShown ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'scroll')

  const modal = (
    <>
      <Backdrop onClick={hide} />
      <ModalWrapper aria-modal tabIndex={-1} role="dialog">
        <ModalBlock>
          <ModalHeader>
            <Heading
              fontSize={{
                base: '16px',
                md: '24px',
              }}
              lineHeight={2}
              textAlign="center"
            >
              {headerText}
            </Heading>
            <CloseButton data-dismiss="modal" aria-label="Close" onClick={hide}>
              <CloseIcon />
            </CloseButton>
          </ModalHeader>
          <ModalContent>{modalContent}</ModalContent>
        </ModalBlock>
      </ModalWrapper>
    </>
  )

  return isShown ? ReactDOM.createPortal(modal, document.body) : null
}
