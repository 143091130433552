import { Text } from '@chakra-ui/react'

const HighlightedText = (text: string, query: string) => {
  if (query !== '' && text.toLowerCase().includes(query.toLowerCase())) {
    const parts = text.split(new RegExp(`(${query})`, 'gi'))

    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <Text as="mark" key={index} color="main_blue" bg="transparent">
              {part}
            </Text>
          ) : (
            part
          ),
        )}
      </>
    )
  }

  return text
}

export default HighlightedText
