import { userIdRegex } from 'lib/helper'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { getUsers } from 'services/user.service'
import { authAtom } from 'stores/atoms'

type RecommendId = {
  status: 'SUCCESS01' | 'ERROR' | 'DEFAULT'
  helpText: string
}

const INITIAL_RECOMMEND_ID_STATE: RecommendId = {
  status: 'DEFAULT',
  helpText: '추천인 아이디 입력 시, 추천인과 가입자 모두에게 혜택을 드립니다.',
}

export function useHandleRecommendId() {
  const [recommendId, setRecommendId] = useState<RecommendId>(INITIAL_RECOMMEND_ID_STATE)
  const [value, setValue] = useState('')
  const authData = useRecoilValue(authAtom)

  useEffect(() => {
    if (value === '') {
      setRecommendId(INITIAL_RECOMMEND_ID_STATE)
      return
    } else if (value === authData.id) {
      setRecommendId({
        status: 'SUCCESS01',
        helpText: '본인 아이디에는 혜택이 적용되지 않습니다.',
      })
      return
    } else if (!userIdRegex.test(value)) {
      setRecommendId({ status: 'ERROR', helpText: '없는 아이디입니다.' })
      return
    }

    const timer = setTimeout(async () => {
      try {
        const {
          data: {
            data: { content },
          },
        } = await getUsers(value)

        setRecommendId({
          status: 'SUCCESS01',
          helpText: `${content[0].name}님의 아이디입니다.`,
        })
      } catch (err) {
        setRecommendId({ status: 'ERROR', helpText: '없는 아이디입니다.' })
      }
    }, 100)

    return () => {
      clearTimeout(timer)
    }
  }, [value])

  const onChangeValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)
  }

  return {
    recommendId: { value, status: recommendId.status, helpText: recommendId.helpText },
    onChangeRecommendId: onChangeValue,
  }
}
