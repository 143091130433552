import { Flex, Input } from '../../common'
import { InputProps } from './Input/Input.types'
import { HelperText } from './TextField.styles'

function TextField({
  borderType = 'LINE',
  status = 'DEFAULT',
  align = 'left',
  isSmall = false,
  helpText,
  ...inputProps
}: InputProps) {
  return (
    <Flex gap={8} flexDirection="column" width="100%">
      <Input
        borderType={borderType}
        status={status}
        align={align}
        isSmall={isSmall}
        {...inputProps}
      />
      {helpText && <HelperText status={status}>{helpText}</HelperText>}
    </Flex>
  )
}

export default TextField
