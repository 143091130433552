import media from 'lib/styles/media'
import palette from 'lib/styles/palette'
import * as React from 'react'
import styled from 'styled-components'

interface TemplateBlockProps {
  backgroundColor?: string
}

const TemplateBlock = styled.div<TemplateBlockProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 512px;

  min-height: 745px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#fff'};
  overflow: auto;
  margin: 42px auto 60px;
  padding: 3rem 42px;
  line-height: 1.5;
  border: 1px solid ${palette.line_gray};
  border-radius: 0.5rem;

  ${media.mobile} {
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 1.5rem;
    margin: 0;
    border-radius: 0;
    border: none;
  }
`

export interface RegisterTemplateProps {
  backgroundColor?: string
  children?: React.ReactNode
}

export const RegisterTemplate: React.FC<RegisterTemplateProps> = ({
  children,
  backgroundColor,
}) => <TemplateBlock backgroundColor={backgroundColor}>{children}</TemplateBlock>
