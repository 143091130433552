const palette = {
  background: '#f9f9fb',
  main_blue: '#227EFF',
  active_color: '#227EFF',
  blue_hover: '#4190FF',
  main_blue_border: 'rgba(34, 126, 255, 0.2)',
  main_blue_transparent: 'rgba(34, 126, 255, 0.05)',
  black_hover: '#363940',
  main_black: '#20232B',
  footer_color: '#707A82',
  default_text_gray: '#9FA1A4',
  disable_gray: '#EFF0F0',
  main_white: '#FFFFFF',
  main_bg_gray: '#F9F9FB',
  sub_bg_gray: '#ECF1F8',
  line_gray: '#DEE2E6',
  line_hover: '#A1A6B6',
  error_color: '#F03E3E',
}

export default palette
