import * as React from 'react'
import { DateChecksBlock, DateLabel, DateCheck } from './DateChecks.styles'
import { CheckboxesProps, DateChecksProps, DateType } from './DateChecks.types'
import { useDateCheck } from './hooks/useDateCheck'

const Checkboxes: React.FC<CheckboxesProps> = ({
  checkboxes,
  setCheckbox,
  setRegularBusinessDayOfWeek,
}) => {
  React.useEffect(() => {
    let regularBusinessDay = checkboxes.reduce((acc: DateType | {}, val: DateType) => {
      acc = { ...acc, [val.label]: val.checked }
      return acc
    }, {})

    setRegularBusinessDayOfWeek(regularBusinessDay)
  }, [checkboxes, setRegularBusinessDayOfWeek])

  return (
    <DateChecksBlock>
      {checkboxes.map((checkbox: any, i: number) => (
        <div key={i}>
          <DateCheck
            id={checkbox.label}
            type="checkbox"
            checked={checkbox.checked}
            onChange={(e) => setCheckbox(i, e.target.checked)}
          />
          <DateLabel htmlFor={checkbox.label}>{checkbox.text}</DateLabel>
        </div>
      ))}
    </DateChecksBlock>
  )
}

export const DateChecks: React.FC<DateChecksProps> = ({ setRegularBusinessDayOfWeek }) => {
  const checkboxes = useDateCheck()
  return <Checkboxes {...checkboxes} setRegularBusinessDayOfWeek={setRegularBusinessDayOfWeek} />
}
