import styled from 'styled-components'

import { Icon } from '@chakra-ui/react'

import { CategoryType } from 'components/CategoriesSelects/Category/Category.types'

import media from 'lib/styles/media'
import palette from 'lib/styles/palette'

interface CategoryItemsProps {
  fetchedCategories: CategoryType[]
  orderedCategories: CategoryType[]
  setCheckingCategory: (index: number, checked: boolean) => void
  setOrderingCategory: (idx: number, category: CategoryType) => void
}

export const CategoryItems: React.FC<CategoryItemsProps> = ({
  fetchedCategories,
  orderedCategories,
  setCheckingCategory,
  setOrderingCategory,
}) => (
  <CategoryItemsBlock>
    {fetchedCategories.map((category: CategoryType, idx: number) => (
      <div key={idx} style={{ width: '100%', position: 'relative' }}>
        <CategoryCheckBox
          id={category.value}
          type="checkbox"
          checked={category.checked}
          onChange={(e) => {
            setCheckingCategory(idx, e.target.checked)
            setOrderingCategory(idx, category)
          }}
        />
        <CategoryCheckboxLabel htmlFor={category.value}>
          <Icon w={6} h={6} mb={1.5} as={category.icon} />
          {category.value}
        </CategoryCheckboxLabel>
        {category.checked && (
          <CategoryCheckboxBadge>
            {orderedCategories.findIndex((cate) => category.name === cate.name) + 1}
          </CategoryCheckboxBadge>
        )}
      </div>
    ))}
  </CategoryItemsBlock>
)

const CategoryItemsBlock = styled.div`
  padding: 1rem;
  padding-bottom: 0;
  padding-top: 0.75rem;
  display: grid;
  justify-items: center;
  grid-auto-flow: row;
  grid-template-columns: repeat(4, 1fr);

  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 1.5rem;
  gap: 0.75rem;
  ${media.mobile} {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
`

const CategoryCheckBox = styled.input`
  display: none;

  &:checked ~ label {
    background-color: ${palette.main_blue};
    border-color: ${palette.main_blue};
    color: #fff;
    > svg {
      path {
        fill: #fff;
      }
    }
  }
  &:hover ~ label {
    border-color: ${palette.line_hover};
  }
`

const CategoryCheckboxLabel = styled.label`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${palette.line_gray};
  color: ${palette.main_black};
  padding: 6px 8px;
  width: 100%;
  border-radius: 0.5rem;
  font-size: 11px;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  > svg {
    path {
      fill: ${palette.default_text_gray};
    }
  }
`

const CategoryCheckboxBadge = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  color: ${palette.main_blue};
  font-size: 14px;
`
