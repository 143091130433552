import { useEffect, useState } from 'react'
import {
  BarberIcon,
  CareIcon,
  EtcIcon,
  EyebrowIcon,
  EyelashIcon,
  FitnessIcon,
  HairIcon,
  MakeupIcon,
  MassageIcon,
  NailIcon,
  PetIcon,
  PilatesIcon,
  SkinIcon,
  TanningIcon,
  TattooIcon,
  WaxingIcon,
} from 'components/Icons/svg/categories'
import { getCategories } from 'services/shop.service'
import { CategoryType } from '../Category/Category.types'

type IconType = Record<string, React.FunctionComponent<React.SVGAttributes<SVGElement>>>

const icons: IconType = {
  NAIL: NailIcon,
  BARBER: BarberIcon,
  MAKEUP: MakeupIcon,
  MASSAGE: MassageIcon,
  SEMI_PERMANENT: EyebrowIcon,
  EYELASHES: EyelashIcon,
  SKINCARE: SkinIcon,
  PET: PetIcon,
  WAXING: WaxingIcon,
  HAIR: HairIcon,
  TATTOO: TattooIcon,
  TANNING: TanningIcon,
  PILATES: PilatesIcon,
  FITNESS: FitnessIcon,
  SCALP_HAIR_CARE: CareIcon,
  ETC: EtcIcon,
}

export const useCategorySelect = () => {
  const [fetchedCategories, setFetchedCategories] = useState<CategoryType[]>([])
  const [orderedCategories, setOrderedCategories] = useState<CategoryType[]>([])
  const getDefaultCategories = async () => {
    try {
      const {
        data: {
          data: { categories },
          errorResponse,
        },
      } = await getCategories()

      if (errorResponse.status !== 200) {
        throw new Error(errorResponse.message)
      }

      const result = categories.map((category: { value: string; name: string }, idx: number) => ({
        id: idx,
        name: category.name,
        value: category.value,
        icon: icons[category.name],
        checked: false,
      }))

      setFetchedCategories(result)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getDefaultCategories()
  }, [])

  const setOrderingCategory = (idx: number, category: CategoryType) => {
    const onFindPetShop = fetchedCategories.find((select) => select.name === 'PET')
    const isPetShopChecked = fetchedCategories[idx].name === 'PET'
    const isAnyshopsChecked = fetchedCategories.some((select) => select.checked)

    if (isAnyshopsChecked && isPetShopChecked) {
      return
    }

    if (onFindPetShop?.checked) {
      return setOrderedCategories(orderedCategories.filter((cate) => cate.id !== category.id))
    }

    if (category.checked) {
      return setOrderedCategories(orderedCategories.filter((cate) => cate.id !== category.id))
    } else {
      return setOrderedCategories((elements) => {
        if (!isAnyshopsChecked && elements) {
          return Array.from(new Set([category]))
        }
        return Array.from(new Set([...elements, category]))
      })
    }
  }

  const setCheckingCategory = (index: number, checked: boolean) => {
    setFetchedCategories((category: CategoryType[]) => {
      const onFindPetShop = fetchedCategories.find((select) => select.name === 'PET')
      const isPetShopChecked = fetchedCategories[index].name === 'PET'
      const isAnyshopsChecked = fetchedCategories.some((select) => select.checked)
      return category.map((item: CategoryType) => {
        if (item.id === index) {
          if (onFindPetShop?.checked) {
            if (!isPetShopChecked) {
              alert('애견 업종은 다른 업종과 중복 선택이 불가합니다.')
              return item
            } else {
              return {
                ...item,
                checked,
              }
            }
          } else if (isAnyshopsChecked) {
            if (isPetShopChecked) {
              alert('애견 업종은 다른 업종과 중복 선택이 불가합니다.')
              return item
            } else {
              return {
                ...item,
                checked,
              }
            }
          }
          return {
            ...item,
            checked,
          }
        } else {
          return item
        }
      })
    })
  }

  return {
    orderedCategories,
    fetchedCategories,
    setOrderingCategory,
    setCheckingCategory,
  }
}
