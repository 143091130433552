import * as React from 'react'
import styled from 'styled-components'
import media from 'lib/styles/media'

type MainTemplateProps = {
  children: React.ReactNode
}

const MainTemplate = ({ children }: MainTemplateProps) => <Block>{children}</Block>

const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${media.mobile} {
    max-width: 100%;
  }
`

export default MainTemplate
