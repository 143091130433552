import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import { HelmetProvider } from 'react-helmet-async'
import { ChakraProvider } from '@chakra-ui/react'
import TagManager from 'react-gtm-module'
import '@fontsource/noto-sans-kr/400.css'
import './lib/styles/index.scss'
import { theme } from 'lib/themes/theme'

import App from 'App'
import { DebugObserver } from 'stores/observers/DebugObserver'

import GlobalStyles from 'GlobalStyles'
import { BrowserRouter as Router } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ThemeProvider } from 'styled-components'

const tagManagerArgs = {
  gtmId: 'GTM-PCFQZ7G',
}

TagManager.initialize(tagManagerArgs)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    environment: process.env.REACT_APP_RUN_MODE,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <DebugObserver />
      <ThemeProvider theme={theme}>
        <ChakraProvider theme={theme}>
          <GlobalStyles />
          <HelmetProvider>
            <Router>
              <App />
            </Router>
          </HelmetProvider>
        </ChakraProvider>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
)
