import { FacebookIcon, InstagramIcon, NaverBlogIcon } from 'components/Icons/svg'
import {
  FooterBlock,
  FooterWrapper,
  FooterColumn,
  FooterIconWrapper,
  FooterContent,
  FooterContentWrapper,
  Divider,
} from './Footer.styles'

export const Footer = () => {
  return (
    <FooterBlock>
      <FooterWrapper>
        <FooterColumn>
          <FooterContentWrapper>
            <FooterContent>
              <a href="https://www.gongbiz.kr/crm/terms.html" target="_blank" rel="noreferrer">
                이용약관
              </a>
            </FooterContent>
            <Divider />
            <FooterContent>
              <a href="https://www.gongbiz.kr/crm/privacy.html" target="_blank" rel="noreferrer">
                개인정보취급방침
              </a>
            </FooterContent>
          </FooterContentWrapper>
          <FooterContentWrapper>
            <FooterContent>(주)헤렌</FooterContent>
            <Divider />
            <FooterContent>대표이사:장진웅</FooterContent>
            <Divider />
            <FooterContent>사업자등록번호 701-87-00540</FooterContent>
          </FooterContentWrapper>
        </FooterColumn>
        <FooterColumn isReverse={true}>
          <FooterContentWrapper>
            <FooterContent>공비서SNS</FooterContent>
            <FooterIconWrapper>
              <a rel="noreferrer" href="https://www.facebook.com/gongbiz/" target="_blank">
                <FacebookIcon />
              </a>

              <a
                rel="noreferrer"
                href="https://www.instagram.com/gongbiz_official/?hl=ko"
                target="_blank"
              >
                <InstagramIcon />
              </a>

              <a rel="noreferrer" href="https://blog.naver.com/bongbong323" target="_blank">
                <NaverBlogIcon />
              </a>
            </FooterIconWrapper>
          </FooterContentWrapper>
          <FooterContentWrapper>
            <FooterContent>고객센터 1600-3145</FooterContent>
          </FooterContentWrapper>
        </FooterColumn>
        <FooterColumn></FooterColumn>
      </FooterWrapper>
    </FooterBlock>
  )
}
