const nameRegex = /^[ㄱ-힣|a-z|A-Z|0-9|]{1,50}$/
const userIdRegex = /^[a-z0-9+]{4,20}$/
const shopNameRegex = /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9~ !@#$%^&*()_+/.,₩\-=\[\]{}\\|;'"“”‘’:<>?※]{1,50}$/
const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()+/.,₩\-_=\[\]{}\\|;'":<>?※])[A-Za-z\d~!@#$%^&*()+/.,₩\-_=\[\]{}\\|;'":<>?※]{8,20}$/
const numberRegex = /[^\d]/g
const emojiRegex = /\p{Extended_Pictographic}/u

const removeEmojis = (value: string) => value.replace(emojiRegex, '')
const parseJwt = (token: string) => {
  if (!token) {
    return
  }
  let base64Url = token.split('.')[1]
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  )
  return JSON.parse(jsonPayload)
}

export {
  userIdRegex,
  nameRegex,
  shopNameRegex,
  passwordRegex,
  numberRegex,
  emojiRegex,
  removeEmojis,
  parseJwt,
}
