import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const queryPramsAtom = atom({
  key: 'queryPrams',
  default: {
    returnUrl: '',
  },
  effects_UNSTABLE: [persistAtom],
})

export { queryPramsAtom }
