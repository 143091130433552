import styled from 'styled-components'
import Text from '../Text'
import { InputProps } from './Input/Input.types'

export const HelperText = styled(Text.S)<Pick<InputProps, 'status'>>`
  color: ${({ theme, status }) => {
    switch (status) {
      case 'SUCCESS01':
        return theme.color.primary[300]
      case 'SUCCESS02':
        return theme.color.gray[300]
      case 'ERROR':
        return theme.color.red[300]
      case 'DISABLED':
        return theme.color.gray[50]
      default:
        return theme.color.gray[300]
    }
  }};
`
