import styled, { css } from 'styled-components'
import { InputProps } from './Input.types'

export const StyledInput = styled.input<InputProps>`
  outline: none;
  border: none;
  width: 100%;
  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  ${({ theme, isSmall }) => css`
    font-size: ${isSmall ? theme.fontSize.s : theme.fontSize.m};
  `}

  ${({ theme, status }) => css`
    color: ${theme.color.gray[700]};
    background: ${theme.color.bg[100]};
    &::placeholder {
      color: ${theme.color.gray[status === 'DISABLED' ? 50 : 300]};
    }
  `}

${({ isSmall, borderType }) => css`
  height: ${isSmall ? '28px' : '44px'};
  padding: ${borderType === 'BOX' ? (isSmall ? '5px 12px' : '12px 18px') : 0};
  border-radius ${borderType === 'BOX' ? (isSmall ? '4px' : '8px') : 0}; 
`}

${({ borderType, theme, status }) => css`
    ${borderType === 'BOX' ? 'border' : 'border-bottom'}: 1px solid
  ${() => {
      switch (status) {
        case 'SUCCESS01':
        case 'SUCCESS02':
          return theme.color.gray[700]
        case 'ERROR':
          return theme.color.red[300]
        case 'DISABLED':
          return theme.color.gray[50]
        default:
          return theme.color.gray[100]
      }
    }};
  `}


:focus {
    ${({ borderType, theme }) => css`
      ${borderType === 'BOX' ? 'border' : 'border-bottom'}: 1px solid ${theme.color.primary[300]};
    `}
  }

  text-align: ${({ align }) => align};
`
