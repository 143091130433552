import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const shopsAtom = atom({
  key: 'shops',
  default: {
    name: '',
    shopNo: '',
    token: null,
  },
  effects_UNSTABLE: [persistAtom],
})

export { shopsAtom }
