import styled, { css } from 'styled-components'

const XXL = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xxl};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.xxl};
  `}
`
const XL = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.xl};
  `}
`
const L = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.l};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.l};
  `}
`
const M24 = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.m};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.l};
  `}
`
const M20 = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.m};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.m};
  `}
`
const S = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.s};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.s};
  `}
`
const XS = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.xs};
  `}
`
export { XXL, XL, L, M24, M20, S, XS }
