import apiService from './api.service'

type MobileCertificationValidateRequest = {
  certificationText: number
  certificationType:
    | 'SMS_CERT'
    | 'LOCK_PASSWORD'
    | 'APP_SIGNUP'
    | 'APP_MODIFY'
    | 'FIND_ID'
    | 'FIND_PASSWORD'
    | 'CHANGE_PASSWORD'
  mobilePhoneNumber: string
}

type MobileCertificationRequest = {
  certificationType:
    | 'SMS_CERT'
    | 'LOCK_PASSWORD'
    | 'APP_SIGNUP'
    | 'APP_MODIFY'
    | 'FIND_ID'
    | 'FIND_PASSWORD'
    | 'CHANGE_PASSWORD'

  mobilePhoneNumber: string
}

type IUserCreateRequestProps = {
  id: string
  mobilePhoneNumber: string
  name: string
  password: string
}

const checkId = (userId: string) =>
  apiService.get('/api/v2/users/check-duplicate', {
    params: {
      id: userId,
    },
  })

const sendAuthPhoneNumber = (payload: MobileCertificationValidateRequest) =>
  apiService.post('/api/v2/certifications/mobile/validate', payload)

const getAuthPhoneNumberCode = (payload: MobileCertificationRequest) =>
  apiService.post('/api/v2/certifications/mobile', payload)

const registerUser = (userCreateRequest: IUserCreateRequestProps) =>
  apiService.post('/api/v2/users', userCreateRequest)

export { checkId, sendAuthPhoneNumber, getAuthPhoneNumberCode, registerUser }
