import apiService from './api.service'

export const getSearchShops = (payload: string) =>
  apiService.get('/api/v2/shops', { params: { query: payload } })

export const getCategories = () => apiService.get('/api/v2/shops/categories')

type RegisterShopProps = {
  name: string
  ownerEmpNo: number
  categories: string[]
  addressBasic: string
  addressDetail: string
  zipCode: string
  addressDong?: string
  recommendId?: number
  regularBusinessDayOfWeek?: {
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
    saturday: boolean
    sunday: boolean
  }
  startBusinessTime?: string
  endBusinessTime?: string
  bookingTimeUnit?: string
}

export const registerShop = (payload: RegisterShopProps) =>
  apiService.post('/api/v2/shops', payload)
