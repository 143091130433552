import {
  ColorType,
  SelectButtonBlock,
  IconWrapper,
  SelectButtonWrapper,
  SelectButtonTitle,
  SelectButtonDescription,
} from './SelectButton.styles'

interface SelectButtonProps extends React.HTMLProps<HTMLButtonElement> {
  title: string
  description: string | React.ReactNode
  icon: React.ReactNode
  color?: ColorType
}

export const SelectButton: React.FC<SelectButtonProps> = ({
  title,
  description,
  icon,
  color = 'primary',
  ...rest
}) => {
  const htmlProps = rest as any

  return (
    <SelectButtonBlock
      color={color}
      {...htmlProps}
      onClick={(e) => {
        if (htmlProps.onClick) {
          htmlProps.onClick(e)
        }
        ;(e.target as HTMLButtonElement).blur()
      }}
    >
      <IconWrapper>{icon}</IconWrapper>
      <SelectButtonWrapper>
        <SelectButtonTitle>{title}</SelectButtonTitle>
        <SelectButtonDescription>{description}</SelectButtonDescription>
      </SelectButtonWrapper>
    </SelectButtonBlock>
  )
}
