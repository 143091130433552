import { Avatar, Flex, Box, Button, Spacer, useMediaQuery } from '@chakra-ui/react'

import AlertDialog from 'components/AlertDialog'
import HighlightedText from 'components/HighlightedText'
import { ShopIcon } from 'components/Icons/svg'
import { useModal } from 'components/Modal/hooks/useModal'
import { ShopItemBlock, ShopNameText, OwnerNameText, AddressText } from './ShopItem.styles'

export type ShopItemType = {
  addressBasic: string
  addressDetail: string
  logoImageUrl: string
  name: string
  ownerName: string
  shopNo: string
}

interface ShopItemProps {
  content: ShopItemType
  query: string
}

const ShopItem: React.FC<ShopItemProps> = ({ content, query }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const { isShown, toggle } = useModal()

  if (!content) {
    return null
  }

  const { addressBasic, addressDetail, logoImageUrl, name, ownerName } = content

  return (
    <ShopItemBlock>
      <Flex align="center" onClick={() => isMobile && toggle()}>
        <Box mr={2}>
          {logoImageUrl ? (
            <Avatar w={10} h={10} src={logoImageUrl} name="shop-image" />
          ) : (
            <Avatar w={10} h={10} bg="#eff0f5" icon={<ShopIcon />} />
          )}
        </Box>
        <Box style={{ overflow: 'hidden' }}>
          <Box display="flex">
            <ShopNameText fontSize="sm" color="default_text_gray">
              {HighlightedText(name, query)}
            </ShopNameText>
            <OwnerNameText fontSize="xs" color="default_text_gray">
              {ownerName}
            </OwnerNameText>
          </Box>
          <AddressText fontSize="xs" color="main_black">
            {addressBasic} {addressDetail}
          </AddressText>
        </Box>

        <Spacer />

        {!isMobile && (
          <Box ml={2}>
            <Button
              bg="#fff"
              _hover={{
                bg: '#fff',
              }}
              borderRadius={100}
              minW={66}
              variant="outline"
              onClick={toggle}
            >
              선택
            </Button>
          </Box>
        )}
      </Flex>

      <AlertDialog isShown={isShown} hide={toggle} content={content} />
    </ShopItemBlock>
  )
}

export default ShopItem
