import media from 'lib/styles/media'
import palette from 'lib/styles/palette'
import styled, { css } from 'styled-components'

export type ColorType = 'primary' | 'transparent' | 'white'

const SelectButtonBlock = styled.button<{ color: ColorType }>`
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  color: white;
  border-radius: 8px;
  padding: 1.75rem 0;
  ${({ color }) => css`
    background-color: ${
    color === 'primary' 
        ? palette.main_blue
        : color === 'white' 
            ? palette.main_white 
            : palette.main_blue_transparent};

    &:hover {
      background-color: ${
          color === 'primary'
              ? '#4190ff'
              : color === 'white'
                  ? palette.main_white
                  : palette.main_blue_transparent
      };
    }
  `};

  
  ${media.mobile} {
    display: flex;
    //padding: 32px 30px 32px 20px;
    padding: 32px 10px 32px 10px;
    flex: 1 1 auto;
    min-width: 100%;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const SelectButtonWrapper = styled.div`
  ${media.mobile} {
    text-align: left;
    margin-left: 12px;
  }
`

const SelectButtonTitle = styled.h2`
  color: #227EFF;
  margin: 0;
  margin-top: 1.25rem;
  font-size: 16px;
  font-weight: 700;
  word-break: keep-all;

  ${media.mobile} {
    margin: 0;
  }
`

const SelectButtonDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 0.625rem;
  color: #20232B;
  font-size: 14px;
  font-weight: 400;
  word-break: keep-all;
  
  .emphasis {
    font-weight: 700;
  }
  
  ${media.mobile} {
    margin-top: 0.5rem;
  }
`

export {
  SelectButtonBlock,
  IconWrapper,
  SelectButtonWrapper,
  SelectButtonTitle,
  SelectButtonDescription,
}
