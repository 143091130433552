import * as React from 'react'
import { DateType } from '../DateChecks.types'

const dates: DateType[] = [
  { label: 'monday', text: '월', checked: true },
  { label: 'tuesday', text: '화', checked: true },
  { label: 'wednesday', text: '수', checked: true },
  { label: 'thursday', text: '목', checked: true },
  { label: 'friday', text: '금', checked: true },
  { label: 'saturday', text: '토', checked: true },
  { label: 'sunday', text: '일', checked: false },
]

export const useDateCheck = () => {
  const [checkboxes, setCheckboxes] = React.useState<DateType[]>(dates)

  const setCheckbox = (index: number, checked: boolean) => {
    const newDateChecks = [...checkboxes]
    newDateChecks[index].checked = checked

    setCheckboxes(newDateChecks)
  }

  return {
    setCheckbox,
    checkboxes,
  }
}
