import * as React from 'react'
import { useCategorySelect } from '../hooks/useCategorySelect'
import { Box, Flex, Text } from '@chakra-ui/react'

import {
  CategoryBlock,
  CategoryInputWrapper,
  CategoryInput,
  CategoryInputRightElement,
  SelectCompleteButton,
} from './Category.styles'
import { ChevronDownIcon } from 'components/Icons/svg'
import { useModal } from 'components/Modal/hooks/useModal'
import { Modal } from 'components/Modal'
import { CategoryItems } from '../CategoryItems'
import { CategoryType } from './Category.types'

interface CategoryProps {
  categorySelects: ReturnType<typeof useCategorySelect>
  setCategories: React.Dispatch<React.SetStateAction<string[]>>
}

export const Category: React.FC<CategoryProps> = ({ categorySelects, setCategories }) => {
  const { isShown, toggle } = useModal()

  React.useEffect(() => {
    let _categories = categorySelects.orderedCategories.reduce(
      (acc: string[], category: CategoryType) => {
        acc.push(category.name)
        return acc
      },
      [],
    )
    setCategories(_categories)
  }, [categorySelects.orderedCategories, setCategories])

  const content = (
    <Flex px={10} pb={30} pt={5} h="100%" flexDirection="column" justifyContent="space-between">
      <Box>
        <Text fontSize="sm" color="default_text_gray" textAlign="center">
          해당하는 업종을 모두 선택해주세요.
        </Text>
        <CategoryItems {...categorySelects} />
      </Box>
      <SelectCompleteButton type="button" onClick={toggle}>
        선택완료
      </SelectCompleteButton>
    </Flex>
  )

  return (
    <CategoryBlock>
      <CategoryInputWrapper onClick={toggle}>
        <CategoryInput
          readOnly
          placeholder="해당하는 업종을 모두 선택해주세요"
          value={categorySelects.orderedCategories
            .map((select: CategoryType) => select.value)
            .join(', ')}
        />
        <CategoryInputRightElement>
          <ChevronDownIcon />
        </CategoryInputRightElement>
      </CategoryInputWrapper>

      <Modal headerText="업종 선택" isShown={isShown} hide={toggle} modalContent={content} />
    </CategoryBlock>
  )
}
