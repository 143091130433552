import media from 'lib/styles/media'
import palette from 'lib/styles/palette'
import styled from 'styled-components'

const DateChecksBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

const DateLabel = styled.label`
  display: inline-block;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid ${palette.line_gray};
  box-sizing: border-box;
  color: #3e4145;
  cursor: pointer;
  ${media.mobile} {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
  }
`

const DateCheck = styled.input`
  display: none;
  &:checked ~ label {
    background-color: ${palette.main_blue};
    border-color: ${palette.main_blue};
    color: #fff;
  }

  &:hover ~ label {
    border-color: ${palette.main_blue};
  }
`

export { DateChecksBlock, DateLabel, DateCheck }
