import styled from 'styled-components'

const ShopListBlock = styled.div`
  position: relative;
`

const ShopListWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  background-color: #fff;
  padding-bottom: 40px;
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px 0 rgba(34, 126, 255, 0.2);
  overflow-y: scroll;
  max-height: 420px;
  position: relative;
`

const ShopListTextBlock = styled.div`
  height: 36px;
  line-height: 36px;
  background-color: #fff;
`

export { ShopListBlock, ShopListWrapper, ShopListTextBlock }
