import apiService from './api.service'

const getUserByEmpNo = (empNo: number) =>
  apiService.get(`/api/v2/users/${empNo}`)

const getUsers = (payload: string) =>
  apiService.get(`/api/v2/users/`, {
    params: {
      id: payload,
    },
  })

export { getUserByEmpNo, getUsers }
