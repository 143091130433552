import * as React from 'react'

interface TimerProps {
  mm: number
  ss: number
  isResetTime: boolean
  setIsResetTime: React.Dispatch<React.SetStateAction<boolean>>
  setisFinishTime: React.Dispatch<React.SetStateAction<boolean>>
  setShowResendButton: React.Dispatch<React.SetStateAction<boolean>>
}

const Timer: React.FC<TimerProps> = ({
  mm,
  ss,
  isResetTime,
  setIsResetTime,
  setisFinishTime,
  setShowResendButton,
}) => {
  const [minutes, setMinutes] = React.useState(mm)
  const [seconds, setSeconds] = React.useState(ss)

  React.useEffect(() => {
    if (isResetTime) {
      setMinutes(mm)
      setSeconds(ss)
      setIsResetTime(false)
    }
  }, [isResetTime, mm, ss, setIsResetTime])

  React.useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 30) {
        setShowResendButton(true)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(countdown)
          setisFinishTime(true)
        } else if (minutes < mm) {
          setMinutes(minutes - 1)
          setSeconds(59)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => clearInterval(countdown)
  }, [minutes, seconds, mm, setMinutes, setSeconds, setisFinishTime, setShowResendButton])

  return (
    <div className="timer">
      <span className="timer__minutes">{minutes}</span>
      <span className="timer__colon">:</span>
      <span className="timer__seconds">{seconds < 10 ? `0${seconds}` : seconds}</span>
    </div>
  )
}

export default Timer
