import React from 'react'
import { useStep } from './hooks/useStep'

type StepProps = {
  children?: React.ReactNode
}

const Step: React.FC<StepProps> = ({ children }) => {
  const { isActive } = useStep()
  return <>{isActive && <>{children}</>}</>
}

export default Step
