import styled from 'styled-components'
import palette from 'lib/styles/palette'

const CategoryBlock = styled.div`
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 42px;
`

const CategoryInputWrapper = styled.div`
  cursor: pointer;
  height: 2.75rem;
  display: flex;
  border: 1px solid ${palette.line_gray};
  border-radius: 0.5rem;
  justify-content: space-between;
  align-items: center;
  transition: all 200ms;
  &:hover {
    border-color: ${palette.main_blue};
  }
`

const CategoryInput = styled.input`
  margin-left: 1rem;
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  color: ${palette.main_blue};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;

  &::placeholder {
    color: #adb5bd;
    font-size: 14px;
  }
`

const CategoryInputRightElement = styled.div`
  padding: 1rem;
`

const SelectBoxCategoryInputWrapper = styled(CategoryInputWrapper)`
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border: none;
`

const SelectBoxBlock = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  background-color: #fff;
  border: 1px solid ${palette.main_blue};
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px 0 rgba(34, 126, 255, 0.2);
  padding: 0.5rem;
  top: 0;
  z-index: 2;
`

const SelectCompleteButton = styled.button`
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0;
  background-color: ${palette.main_black};
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: ${palette.black_hover};
  }
`

export {
  CategoryBlock,
  CategoryInputWrapper,
  CategoryInput,
  CategoryInputRightElement,
  SelectBoxCategoryInputWrapper,
  SelectBoxBlock,
  SelectCompleteButton,
}
