import { useState } from 'react'
import { shopNameRegex } from 'lib/helper'

type ShopName = {
  value: string
  status: 'SUCCESS01' | 'ERROR' | 'DEFAULT'
  helpText: string
}

const INITIAL_SHOP_NAME_STATE: ShopName = {
  value: '',
  status: 'DEFAULT',
  helpText: '최대 50자까지 입력 가능합니다.',
}

export function useHandleShopName() {
  const [shopName, setShopName] = useState<ShopName>(INITIAL_SHOP_NAME_STATE)

  const validateShopName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const isSuccess = shopNameRegex.test(value)

    if (value === '') {
      setShopName(INITIAL_SHOP_NAME_STATE)
    } else {
      setShopName({
        value,
        status: isSuccess ? 'SUCCESS01' : 'ERROR',
        helpText: isSuccess
          ? '사용할 수 있는 샵 이름입니다.'
          : '50자를 초과했거나, 사용할 수 없는 문자가 있습니다.',
      })
    }
  }

  return {
    shopName,
    validateShopName,
  }
}
