import { extendTheme } from '@chakra-ui/react'
import palette from 'lib/styles/palette'
import { Button } from './Button'

export const theme = extendTheme({
  components: {
    Button,
  },

  fonts: {
    heading: 'Pretendard, Pretendard_Fallback',
    body: 'Pretendard, Pretendard_Fallback',
  },

  colors: palette,

  color: {
    primary: {
      100: '#ECF3FF',
      150: '#BDD8FF',
      200: '#87B9FF',
      300: '#227EFF',
      400: '#1E60F7',
      500: '#1A42EF',
    },
    secondary: {
      100: '#ECEAFE',
      200: '#998CFF',
      300: '#462EFF',
      400: '#2C25F6',
      500: '#121BED',
    },
    tertiary: {
      100: '#EFFAFA',
      200: '#90E3E2',
      300: '#31CDC9',
      400: '#3EB7B3',
      500: '#4AA19D',
    },
    red: {
      100: '#FCEEED',
      200: '#F69696',
      300: '#F03E3E',
      400: '#DD342D',
      500: '#CA291B',
    },
    yellow: {
      100: '#FEFAF1',
      200: '#FFE2A7',
      300: '#FFC95C',
      400: '#F8B549',
      500: '#F0A235',
    },
    gray: {
      0: '#FFFFFF',
      50: '#EFF0F0',
      100: '#DFE0E1',
      200: '#BFC0C2',
      300: '#9FA1A4',
      400: '#808186',
      500: '#606268',
      600: '#404249',
      700: '#20232B',
    },
    bg: {
      100: '#FFFFFF',
      200: '#F9F9FB',
      300: '#ECF3FF',
    },
    shadow: {
      1: '#227EFF1A',
      2: '#00000033',
    },
    dim: '#0000004D',
  },
  fontSize: {
    xxl: '24px',
    xl: '20px',
    l: '16px',
    m: '14px',
    s: '12px',
    xs: '11px',
  },
  fontFamily: 'Pretendard, Pretendard_Fallback',
  fontWeight: {
    bold: 700,
    medium: 500,
    regular: 400,
  },
  lineHeight: {
    xxl: '36px',
    xl: '32px',
    l: '24px',
    m: '20px',
    s: '18px',
    xs: '16px',
  },
  spacing: [
    '4',
    '8',
    '12',
    '16',
    '20',
    '24',
    '28',
    '32',
    '36',
    '40',
    '44',
    '48',
    '52',
    '60',
    '100',
  ],
})
