import { passwordRegex } from 'lib/helper'
import { useState } from 'react'

export function useHandlePassword() {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  const getPasswordConfirmStatus = () => {
    switch (true) {
      case passwordConfirm === '':
        return 'DEFAULT'
      case password !== passwordConfirm:
        return 'ERROR'
      case password === passwordConfirm:
        return 'SUCCESS01'
      default:
        return 'DEFAULT'
    }
  }

  const getPasswordStatus = () => {
    switch (true) {
      case password === '':
        return 'DEFAULT'
      case !passwordRegex.test(password):
        return 'ERROR'
      case passwordRegex.test(password):
        return 'SUCCESS01'
      default:
        return 'DEFAULT'
    }
  }

  const getHelptext = () => {
    switch (true) {
      case password === '' || passwordConfirm === '':
      case getPasswordStatus() === 'ERROR':
      default:
        return '영문+숫자+특수문자로 8~20자 이내로 입력해주세요.'
      case passwordConfirm !== '' && password !== passwordConfirm:
        return '동일한 비밀번호를 입력해주세요.'
      case getPasswordStatus() === 'SUCCESS01' && getPasswordConfirmStatus() === 'SUCCESS01':
        return '사용할 수 있는 비밀번호입니다.'
    }
  }

  return {
    password,
    passwordConfirm,
    getPasswordConfirmStatus,
    getPasswordStatus,
    getHelptext,
    onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
    onChangePasswordConfirm: (e: React.ChangeEvent<HTMLInputElement>) =>
      setPasswordConfirm(e.target.value),
    isShowErrorText: getPasswordConfirmStatus() === 'ERROR' || getPasswordStatus() === 'ERROR',
    showPassword,
    showPasswordConfirm,
    onChangePasswordType: () => setShowPassword(!showPassword),
    onChangePasswordConfirmType: () => setShowPasswordConfirm(!showPasswordConfirm),
  }
}
