import * as React from 'react'
import DaumPostcode from 'react-daum-postcode'

type AddressInfoType = {
  addressBasic: string
  zipCode: number
  dong: string
}

interface IPostcodeProps {
  toggle: () => void
  setAddress: React.Dispatch<React.SetStateAction<AddressInfoType>>
}

const Postcode: React.FC<IPostcodeProps> = ({ toggle, setAddress }) => {
  const handleComplete = (data: any) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    setAddress({
      addressBasic: fullAddress,
      zipCode: data.zonecode,
      dong: data.bname,
    })
    toggle()
  }

  return (
    <DaumPostcode style={{ height: '550px' }} onComplete={handleComplete} maxSuggestItems={5} />
  )
}

export default Postcode
