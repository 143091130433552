export const Button = {
  baseStyle: {
    borderRadius: 'lg',
    outline: 'none',
    boxShadow: 'none',

    _focus: {
      boxShadow: 'none',
    },
  },

  sizes: {
    lg: {
      fontSize: 'md',
      py: 7,
    },

    md: {
      h: 45,
      fontSize: 'sm',
    },
  },

  variants: {
    solid: {
      bg: 'main_blue',

      _hover: {
        _disabled: {
          bg: 'disable_gray',
          opacity: 1,
        },

        bg: 'blue_hover',
      },

      _active: {
        bg: 'main_blue',
        outline: 'none',
      },

      _focus: {
        boxShadow: 'none',
      },

      _disabled: {
        bg: 'disable_gray',
        opacity: 1,
      },

      color: 'white',
    },

    outline: {
      borderColor: 'line_gray',
      color: 'main_black',
      fontWeight: 'md',

      _hover: {
        bg: 'white',
        borderColor: 'line_hover',
        _disabled: {
          opacity: 1,
          borderColor: 'line_gray',
          color: 'default_text_gray',
        },
      },

      _active: {
        bg: 'white',
      },

      _disabled: {
        opacity: 1,
        color: 'default_text_gray',
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
}
