import * as React from 'react'
import ReactDOM from 'react-dom'
import { AxiosError } from 'axios'
import { Center, Button, Flex, Box, Heading, Text } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { requestWorkPlace } from 'services/employee.service'
import { shopsAtom } from 'stores/atoms/shops'
import { authAtom } from 'stores/atoms'
import { Backdrop, AlertDialogWrapper, AlertDialogBlock } from './AlertDialog.styles'
import { AlertDialogProps } from './AlertDialog.types'

const AlertDialog: React.FC<AlertDialogProps> = ({ isShown, hide, content }) => {
  const authData = useRecoilValue(authAtom)

  const setEmployeeInfo = useSetRecoilState(shopsAtom)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)

  if (!content) {
    return null
  }

  const { addressBasic, addressDetail, name, shopNo, ownerName } = content

  const onClickJoinShop = async () => {
    if (!authData.empNo) {
      return
    }

    const payload = {
      empNo: authData.empNo,
      shopNo: shopNo,
    }

    setIsLoading(true)

    try {
      const {
        data: {
          data: { shopNo, token },
          errorResponse: { status },
        },
      } = await requestWorkPlace(payload)

      if (status !== 200) {
        throw new Error('Error occurs')
      }

      setEmployeeInfo({ name, shopNo, token })
      navigate('/register/employee/success')
      return
    } catch (err: unknown) {
      const error = err as AxiosError
      alert(error.response?.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  const alertDialogContent = (
    <>
      <Backdrop onClick={hide} />
      <AlertDialogWrapper>
        <AlertDialogBlock>
          <Box mb={27}>
            <Center flexDirection="column">
              <Heading size="md">
                <Box textAlign="center">
                  <Text as="span">
                    <Text as="span" color="main_blue">
                      {name}
                    </Text>
                    에
                  </Text>
                </Box>
                <Text>입사신청 하시겠습니까?</Text>
              </Heading>
              <Box textAlign="center">
                <Text as="span" fontSize="sm" color="default_text_gray" mb={3}>
                  {addressBasic}
                  {addressDetail}
                </Text>
                <Text fontWeight={500} fontSize="sm" color="default_text_gray">
                  점장 {ownerName}
                </Text>
              </Box>
            </Center>
          </Box>
          <Flex>
            <Button isFullWidth mr={1} variant="outline" onClick={hide}>
              취소
            </Button>
            <Button
              isLoading={isLoading}
              isFullWidth
              bg="main_black"
              _hover={{ bg: 'black_hover' }}
              _active={{ bg: 'main_black' }}
              onClick={onClickJoinShop}
            >
              신청
            </Button>
          </Flex>
        </AlertDialogBlock>
      </AlertDialogWrapper>
    </>
  )

  return isShown ? ReactDOM.createPortal(alertDialogContent, document.body) : null
}

export default AlertDialog
