import React from 'react'

import * as Sentry from '@sentry/react';

class ErrorBoundary extends React.Component<{
  children?: React.ReactNode
}> {
  state = {
    hasError: false,
    chunkError: false,
  }
  static getDerivedStateFromError(error: Error) {
    if (error.name === 'ChunkLoadError') {
      return {
        chunkError: true,
      }
    }
    return { hasError: true }
  }
  componentDidCatch(error: Error) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.captureException(error)
    }
  }

  handleResolveError = () => {
    this.setState({
      hasError: false,
    })
  }
  render() {
    if (this.state.chunkError) {
      return <p>Chunk Error입니다.</p>
    }

    if (this.state.hasError) {
      return <p>에러 발생</p>
    }

    return (
      <ErrorBoundaryWrapper hasError={this.state.hasError}>
        {this.props.children}
      </ErrorBoundaryWrapper>
    )
  }
}

type ErrorBoundaryWrapperProps = {
  children: React.ReactNode
  hasError: boolean
}
function ErrorBoundaryWrapper(props: ErrorBoundaryWrapperProps) {
  return <>{props.children}</>
}

export default ErrorBoundary
