import media from 'lib/styles/media'
import styled from 'styled-components'

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
`
const AlertDialogWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: 324px;

  outline: 0;
`
const AlertDialogBlock = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  width: 324px;
  border-radius: 0.5rem;
  padding: 0.5rem;
  padding-top: 24px;
  box-shadow: 20px 20px 20px 0 rgba(113, 140, 150, 0.3);

  ${media.mobile} {
    width: 280px;
  }
`

const AlertDialogButtonWrapper = styled.div`
  display: flex;
`

export {
  Backdrop,
  AlertDialogWrapper,
  AlertDialogBlock,
  AlertDialogButtonWrapper,
}
