import styled from 'styled-components'
import { FlexProps } from './Flex.types'

const Flex = styled.div<FlexProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'start'};
  align-items: ${({ alignItems }) => alignItems || 'start'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  gap: ${({ gap }) => (gap && gap + 'px') || '0px'};
  width: ${({ width }) => width};
`

export default Flex
