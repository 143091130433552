import styled from 'styled-components'
import { Text } from '@chakra-ui/react'
import palette from 'lib/styles/palette'

const ShopItemBlock = styled.li`
  list-style-type: none;
  padding: 12.5px 30px;
  align-items: center;

  &:hover {
    background-color: ${palette.main_blue_transparent};
  }
`

const ShopNameText = styled(Text)`
  display: inline-block;
  margin-right: 0.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
`

const OwnerNameText = styled(Text)`
  font-weight: 500;
  line-height: 2;
  word-break: keep-all;
`

const AddressText = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
`

export { ShopItemBlock, ShopNameText, OwnerNameText, AddressText }
