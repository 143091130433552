import { Link } from '@chakra-ui/react'

import { GongbizLogoIcon } from '../../components/Icons/svg'
import { HeaderBlock } from './Header.styles'

export const Header = () => {
  return (
    <HeaderBlock>
      <Link href={process.env.REACT_APP_HOST_URL}>
        <GongbizLogoIcon />
      </Link>
    </HeaderBlock>
  )
}
