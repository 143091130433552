import * as React from 'react'
import styled from 'styled-components'
import { Box, Button, Heading, Flex, Text } from '@chakra-ui/react'
import { ThanksIcon } from 'components/Icons/svg'
import { useRecoilValue } from 'recoil'
import { shopsAtom } from 'stores/atoms/shops'

import { queryPramsAtom } from 'stores/atoms/users'
import { RegisterTemplate } from 'template/RegisterTemplate'

export const RegisterSuccessPage = (): React.ReactElement => {
  const shopInfo = useRecoilValue(shopsAtom)
  const queryParamsAtom = useRecoilValue(queryPramsAtom)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmitToken = async () => {
    setIsLoading(true)
    let newInput = document.createElement('input')
    newInput.type = 'hidden'
    newInput.name = 'token'
    newInput.value = shopInfo.token

    if (formRef.current) {
      formRef.current.method = 'post'
      formRef.current.action = queryParamsAtom.returnUrl
      formRef.current.appendChild(newInput)
      formRef.current.submit()
    }
    return
  }

  return (
    <RegisterTemplate>
      <RegisterFormBlock>
        <StyledBox>
          <ThanksIcon className="icon" />
          <Box mt={10}>
            <Heading size="md">
              <Box>
                <Text as="span">
                  <Text as="span" color="main_blue">
                    {shopInfo.name}
                  </Text>
                  에
                </Text>
              </Box>
              <Text>입사신청이 완료되었습니다.</Text>
            </Heading>
            <Text mt={6} fontSize="sm">
              승인이 완료되면 공비서 서비스를 이용할 수 있습니다.
            </Text>
          </Box>
        </StyledBox>
        <FormFooter>
          <form ref={formRef}>
            <Button isLoading={isLoading} size="lg" isFullWidth onClick={onSubmitToken}>
              완료
            </Button>
          </form>
        </FormFooter>
      </RegisterFormBlock>
    </RegisterTemplate>
  )
}

const RegisterFormBlock = styled(Box)`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`

const StyledBox = styled(Box)`
  .icon {
    margin: 0 auto;
    margin-top: 112px;
  }
`

const FormFooter = styled(Flex)`
  justify-content: center;
  flex-direction: column;
  margin-top: auto;
  gap: 1.25rem;
`
