import { useState } from 'react'
import { nameRegex, numberRegex } from 'lib/helper'

type UserName = {
  value: string
  status: 'SUCCESS01' | 'ERROR' | 'DEFAULT'
  helpText: string
}

type MobileNumber = {
  value: string
  status: 'SUCCESS02' | 'DEFAULT'
  helpText: string
}

const INITIAL_USER_NAME_STATE: UserName = {
  value: '',
  status: 'DEFAULT',
  helpText: '휴대폰 명의자를 입력해주세요.',
}

const INITIAL_MOBILE_NUMBER_STATE: MobileNumber = {
  value: '',
  status: 'DEFAULT',
  helpText: '- 는 제외하고 숫자만 입력해주세요.',
}

export function useHandleMobile() {
  const [mobileNumber, setMobileNumber] = useState<MobileNumber>(INITIAL_MOBILE_NUMBER_STATE)
  const [userName, setUserName] = useState<UserName>(INITIAL_USER_NAME_STATE)

  const validateUserName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const isSuccess = nameRegex.test(value)

    if (value === '') {
      setUserName(INITIAL_USER_NAME_STATE)
    } else {
      setUserName({
        value,
        status: isSuccess ? 'SUCCESS01' : 'ERROR',
        helpText: isSuccess
          ? '확인되었습니다.'
          : '50자 이상의 텍스트, 특수 문자는 입력할 수 없습니다.',
      })
    }
  }

  const onChangeMobileNumber = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(numberRegex, '')

    setMobileNumber({
      ...mobileNumber,
      value,
      status: !!value ? 'SUCCESS02' : 'DEFAULT',
    })
  }

  return {
    userName,
    validateUserName,
    mobileNumber,
    onChangeMobileNumber,
  }
}
