import axios from 'axios'

const host = process.env.REACT_APP_HOST_URL

const apiService = axios.create({
  baseURL: host,
  withCredentials: true,
  headers: {
    'GD-Auth-Token': process.env.REACT_APP_API_KEY,
  },
})

export default apiService
