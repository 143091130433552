import media from 'lib/styles/media'
import styled from 'styled-components'

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`
const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
`
const ModalBlock = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  width: 500px;
  border-radius: 0.5rem;
  box-shadow: 20px 20px 20px 0 rgba(113, 140, 150, 0.3);

  ${media.mobile} {
    width: 100%;
    height: 100vh;
    flex: 1;
    box-sizing: border-box;
    border-radius: 0;
  }
`
const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1.25rem;
  position: relative;
  box-sizing: border-box;
  height: 3.5rem;
  vertical-align: middle;
  margin-left: auto;
`

const HeaderText = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
`

const CloseButton = styled.div`
  border: none;
  border-radius: 0.5rem;
  background: none;
  padding: 0.5rem;
  right: 0.5rem;
  top: 1rem;
  position: absolute;
  display: flex;
  :hover {
    cursor: pointer;
  }
`

const ModalContent = styled.div`
  overflow-x: hidden;
  border-radius: 0.5rem;

  ${media.mobile} {
    width: 100%;
    height: calc(100% - 56px);
    box-sizing: border-box;
  }
`

export { ModalWrapper, Backdrop, ModalBlock, ModalHeader, HeaderText, CloseButton, ModalContent }
