import { Text } from '@chakra-ui/react'
import ShopItem, { ShopItemType } from './ShopItem'
import { ShopListBlock, ShopListWrapper, ShopListTextBlock } from './ShopList.styles'

interface ShopListProps {
  data: any
  query: string
}

const ShopList: React.FC<ShopListProps> = ({ data, query }) => {
  if (!data) {
    return null
  }

  const {
    data: { content, numberOfElements },
  } = data

  return (
    <ShopListBlock>
      <ShopListWrapper>
        <ShopListTextBlock>
          <Text align="center" fontSize={12} color="default_text_gray">
            총 {numberOfElements}개의 샵이 검색되었습니다.
          </Text>
        </ShopListTextBlock>

        <ul>
          {content.map((c: ShopItemType, index: number) => (
            <ShopItem key={index} content={c} query={query} />
          ))}
        </ul>
      </ShopListWrapper>
    </ShopListBlock>
  )
}

export default ShopList
